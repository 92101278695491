import * as React from "react";
//import { Link } from "gatsby";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="pt-12 px-0 bg-transparent">
        <div className="taglist text-center text-base text-gray-400">
        © 2022 shintakoji All Rights Reserved
        </div>
      </footer>
    );
  }
};

export default Footer;
