import React from "react";
import { Link } from "gatsby";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger() {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  }

  render() {
    return (
      <nav
        className="lg:items-stretch	lg:flex bg-white min-h-12 relative z-30"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="grow my-0 mx-auto relative block md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-lg">
            {/* Hamburger menu */}
            {/* navbar-burger burger */}
            {/* hotdog */}
            <div
              className={`hotdog ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              role="menuitem"
              tabIndex={0}
              onKeyPress={() => this.toggleHamburger()}
              onClick={() => this.toggleHamburger()}
            >
              <span className="spa1" />
              <span className="spa2" />
              <span className="spa3" />
            </div>
          <div
            id="navMenu"
            className={`nabi ${this.state.navBarActiveClass}`} //navbar-menu //cursor-pointer lg:items-center lg:flex flex-grow-0 flex-shrink-0 text-gray-600 leading-8 py-2 px-3 relative
          >
            <div className="lg:justify-start mr-auto lg:items-stretch lg:flex text-center text-xl font-bold"> 
              <Link className="cursor-pointer lg:items-center block lg:flex flex-grow-0 flex-shrink-0 text-text-primary leading-8 py-2 px-3 relative hover:text-gray-400 transition" to="/"> 
                🏠 Home
              </Link>
              <Link className="cursor-pointer lg:items-center block lg:flex flex-grow-0 flex-shrink-0 text-text-primary leading-8 py-2 px-3 relative hover:text-gray-400 transition" to="/about">
                🙍‍♂️ About
              </Link>
              <Link className="cursor-pointer lg:items-center block lg:flex flex-grow-0 flex-shrink-0 text-text-primary leading-8 py-2 px-3 relative hover:text-gray-400 transition" to="/contact">
                📧 Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
